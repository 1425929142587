<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Notification rules')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="notificationTasks"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="notificationTasksLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-badge
                  :color="stateColors[props.row.state]"
                  text-color="dark"
                  class="q-pa-sm"
                >
                  {{ $t(props.row.state) }}
                </q-badge>
              </q-td>

              <q-td
                key="title"
                :props="props"
              >
                {{ props.row.title }}
              </q-td>

              <q-td
                key="channel"
                :props="props"
              >
                <span v-if="props.row._embedded.channel">
                  {{ props.row._embedded.channel.name }}
                </span>
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import Search from '../../components/search/Search.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../../components/global/TableMixin'

export default {
  name: 'Tasks',
  components: {
    Search
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Title'),
          name: 'title',
          align: 'left'
        },
        {
          label: this.$t('Channel'),
          name: 'channel',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark'
      }
    }
  },
  computed: {
    ...mapGetters([
      'notificationTasks',
      'totalNotificationTasksNumber',
      'notificationTasksLoading',
      'notificationTasksPage',
      'appOptions'
    ])
  },
  mounted () {
    this.pagination = {
      ...this.pagination,
      page: this.notificationTasksPage,
      rowsNumber: this.totalNotificationTasksNumber
    }

    if (this.notificationTasks.length > 0) {
      return
    }

    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadNotificationTasks'
    ]),
    ...mapMutations([
      'setNotificationTask'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    loadDefaultItems () {
      return this.onRequest({})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadNotificationTasks(this.serverParams)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    create () {
      this.$router.push('/settings/notifications/rules/entity')
    },
    onRowDblClick (row) {
      this.setNotificationTask(row)
      this.$router.push('/settings/notifications/rules/entity/' + row.id)
    }
  }
}
</script>
